import $ from 'legacy/vendor/jquery.custom'

import { GAReporter, GAEvents } from 'lib/GoogleAnalytics'
import EstatelyEvents           from 'legacy/lib/EstatelyEvents'

import ComponentLoader          from 'legacy/lib/ComponentLoader'
import Cookies                  from 'legacy/lib/Cookies'
import redirectToSignupUrl      from 'legacy/lib/redirect-to-signup-url'

export default class SaveSearch extends ComponentLoader {
  static COOKIE = 'save-search'

  static mount() {
    $(document)
      .on(EstatelyEvents.STATE_CHANGE.LOGIN,  this.onLogin)
      .on(EstatelyEvents.SAVE_SEARCH.REQUEST, this.onRequest)
      .on(EstatelyEvents.SAVE_SEARCH.SUCCESS, this.onSuccess)
      .on(EstatelyEvents.SAVE_SEARCH.ERROR,   this.onError)

    this.restoreSaveSearchFromCookie()
  }

  static onLogin = () => {
    $('.save-search-from-listing-cta').remove()
  }

  static onRequest = (event) => {
    const requestingElement = event.target
    const formData          = this.normalizeSaveSearchData(requestingElement)

    if (!window.estately.data.is_user_logged_in && !formData.has('email')) {
      GAReporter.trackEvent(GAEvents.SAVE_SEARCH.SUBMIT.REDIRECT_TO_SIGNUP)
      this.authenticateFirst(formData)
    } else {
      GAReporter.trackEvent(GAEvents.SAVE_SEARCH.SUBMIT.NO_REDIRECT)
      this.save(requestingElement, formData)
    }
  }

  static onSuccess = async (event, modalContent, requestingElement) => {
    GAReporter.trackEvent(GAEvents.SAVE_SEARCH.SUCCESS)
    Cookies.remove(this.COOKIE)

    // Force a refresh of logged-in content (even if already logged in)
    window.estately.data.is_user_logged_in = true
    $(event.target).trigger(EstatelyEvents.STATE_CHANGE.LOGIN)

    const { default: SaveSearchConfirmationModal } = await this.modalImport
    SaveSearchConfirmationModal.lazyLoad(modalContent, requestingElement)
  }

  static onError = (event, error) => {
    GAReporter.trackEvent(GAEvents.SAVE_SEARCH.ERROR, { eventLabel: error.responseText })
  }

  static async save(element, formData) {
    try {
      this.modalImport = this.modalImport || import('packs/lazy/SaveSearchConfirmationModal')

      const modalContent = await $.ajax({
        type: 'POST',
        url:  '/saved_searches/create',
        data: formData,

        // These need to be disabled since we’re using a FormData() object
        processData: false,
        contentType: false,
      })

      $(element).trigger(EstatelyEvents.SAVE_SEARCH.SUCCESS, [modalContent, element])
    } catch (error) {
      $(element).trigger(EstatelyEvents.SAVE_SEARCH.ERROR, [error])
    }
  }

  static normalizeSaveSearchData(target) {
    const data = new FormData(target.tagName === 'FORM' ? target : undefined)

    if (!data.has('bookmark')) {
      const canonicalURL = document.querySelector('link[rel="canonical"]').href
      data.append('bookmark', canonicalURL)
    }

    return data
  }

  static authenticateFirst(formData) {
    Cookies.set(this.COOKIE, Object.fromEntries(formData), { maxAge: 3600 })

    return redirectToSignupUrl('save_search_from_area')
  }

  static restoreSaveSearchFromCookie() {
    if (!window.estately.data.is_user_logged_in) return

    const savedSearchParams = Cookies.get(this.COOKIE)
    if (!savedSearchParams) return

    // Convert cookie data to a FormData object
    const formData = new FormData()
    Object.entries(savedSearchParams).forEach(([key, val]) => {
      formData.append(key, val)
    })

    this.save(document, formData)
  }
}
