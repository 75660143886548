import $ from 'legacy/vendor/jquery.custom'

import { GAReporter, GAEvents } from 'lib/GoogleAnalytics'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class Accordion extends ComponentLoader {
  static EXPAND_EVENTS = {
    // data-ref        event
    'home-attributes': GAEvents.HOME_ATTRIBUTES.EXPAND,
    'school-scores':   GAEvents.SCHOOLS.EXPAND,
  }

  static mount() {
    $('.js-accordion').click((event) => {
      this.onToggle(event)
    })
  }

  static onToggle(event) {
    if (!event.currentTarget.matches('.js-accordion')) return

    const accordion = event.currentTarget
    const panel = accordion.getAttribute('aria-controls')

    const $accordionBody = $(`#${panel}`)

    const isExpanded = accordion.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      $accordionBody.addClass('hidden')
      accordion.setAttribute('aria-expanded', 'false')
    } else {
      $accordionBody.removeClass('hidden')
      accordion.setAttribute('aria-expanded', 'true')

      const expandEvent = this.EXPAND_EVENTS[accordion.dataset.ref]
      if (expandEvent) GAReporter.trackEvent(expandEvent)
    }
  }
}
