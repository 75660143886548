import $ from 'legacy/vendor/jquery.custom'
import { GAReporter, GAEvents } from 'lib/GoogleAnalytics'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class NativeShareButton extends ComponentLoader {
  static mount() {
    if (navigator.share) $(document).on('click', '.gtm-native-share-sheet', this.onClick)
  }

  static initialize() {
    if (!navigator.share) $('.gtm-native-share-sheet').remove()
  }

  static onClick = async (event) => {
    event.preventDefault()

    const data = event.currentTarget.dataset
    const url  = data.url || document.querySelector('link[rel=canonical]').href || window.location.href
    const eventLabel = `via-${data.source}`

    GAReporter.trackEvent(GAEvents.NATIVE_SHARE_SHEET.OPEN, { eventLabel })

    try {
      await navigator.share({ text: data.text, url })

      GAReporter.trackEvent(GAEvents.NATIVE_SHARE_SHEET.SHARE, { eventLabel })
      GAReporter.pushEventToGTM({ event: 'share' })
    } catch {
      GAReporter.trackEvent(GAEvents.NATIVE_SHARE_SHEET.CANCEL, { eventLabel })
    }
  }
}
